//bootstrap
import Card from "react-bootstrap/Card";

function ServicesVlozkovaniKominu(params) {
  return (
    <Card>
      <Card.Header className="bg-secondary  text-white text-center">
        Vložkování komínu
      </Card.Header>
      <Card.Body>
        <Card.Text>
          je rychlý a cenově výhodný způsob rekonstrukce zejména starších
          komínů. Vložkování spočíva v tom, že se stávající komínový průduch
          opatří vložkou příslušného rozměru, která zaručí bezpečný odvod spalin
          do volného ovzduší. Moderní spotřebiče na plynna i pevná paliva kladou
          vysoké nároky na odtah spalin.
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Kdy komín vyvložkovat</Card.Title>
        <Card.Text>
          <ul className="services-ul">
            <li>komínový průduch neodpovídá platným předpisům</li>
            <li>přechod na jiný druh paliva</li>
            <li>špatný tah komína</li>
            <li>kondenzát proniká do stěn komínového tělesa</li>
            <li>v místnosti je cítit zápach spalin</li>
            <li>původní vložka již neplní svůj účel</li>
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ServicesVlozkovaniKominu;
