import React from "react";

//bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

//styles
import "./TheFooter.scss";

function TheFooter() {
  return (
    <section className="bg-dark mt-5 shadow-lg">
      <Container>
        <Row className="p-5 text-white">
          <Col lg={6} md={12}>
            <h5 className="text-lg-start text-center">Kontakty</h5>
            <ListGroup as="ul" className="d-lg-inline-block mb-lg-0 mb-5">
              <ListGroup.Item as="li">
                <span>
                  <i className="fa-solid fa-user"></i>
                  <span className="bold">Martin Juroš</span>
                </span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <i className="fa-solid fa-house"></i>
                <span className="bold">Prachatice</span>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <a href="tel:+420602148756">
                  <i className="fa-solid fa-phone"></i>
                  <span>+420 602 148 756</span>
                </a>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <a href="mailto:info@kominictvi-prachatice.cz">
                  <i className="fa-solid fa-envelope"></i>
                  <span>kominictvi-prachatice@seznam.cz</span>
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col lg={6} md={12}>
            <h5 className="mb-3">Kdy mě můžete kontaktovat</h5>
            <Table className="text-white">
              <tbody className="fw-normal">
                <tr>
                  <td>Po - Pá:</td>
                  <td>8:00 - 17:00</td>
                </tr>
                <tr>
                  <td>So - Ne:</td>
                  <td>Pouze po předchozí domluvě</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <div className="copyright text-white text-center">
        ©2022 Všechna práva vyhrazena
      </div>
    </section>
  );
}
export default TheFooter;
