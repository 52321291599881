import React, { useEffect, useRef, useState } from "react";

//emailjs
import emailjs from "@emailjs/browser";

//bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

//styles & assets
import "./Contact.scss";

function Contact() {
  const [status, setStatus] = useState("");
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4gii4gf",
        "template_5gucqmk",
        form.current,
        "QlA4NR-nICsIoj6Fh"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("Váš email byl úspěšne odeslán.");
        },
        (error) => {
          console.log(error.text);
          setStatus("Nepodařilo se nám odeslat Váš email.");
        }
      );
    e.target.reset();
  }

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        setStatus("");
      }, 5000);
    }
  }, [status]);

  return (
    <section className="contact mt-5 wow zoomIn">
      <Container>
        <h2 className="display-5 mb-5 text-center">
          Kontaktujte mě přes e-mail
        </h2>

        <Form ref={form} onSubmit={sendEmail}>
          <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
            <Form.Label>Vaše emailová adresa</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Vaše emailová adresa"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Vaše jméno</Form.Label>
            <Form.Control type="text" name="name" placeholder="Vaše jméno" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Předmět</Form.Label>
            <Form.Control type="text" placeholder="Předmět" name="subject" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vaše zpráva</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Vaše zpráva..."
              name="message"
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between align">
            <Button variant="primary" type="submit">
              Odeslat
            </Button>
            {status && renderAlert(status)}
          </Form.Group>
        </Form>
      </Container>
    </section>
  );

  function renderAlert() {
    return (
      <div className="text-center align-self-center">
        <span className="pe-2">{status}</span>
      </div>
    );
  }
}
export default Contact;
