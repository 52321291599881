import { Routes, Route } from "react-router-dom";

//styles
import "./App.scss";

//components
import TheFooter from "./components/TheFooter";
import TheNavigation from "./components/TheNavigation";

//views
import Home from "./views/Home";
import Services from "./views/Services";
import Contact from "./views/Contact";
import PriceList from "./views/PriceList";

//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <header>
        <TheNavigation />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sluzby" element={<Services />} />
          <Route path="/cenik" element={<PriceList />} />
          <Route path="/kontakt" element={<Contact />} />
        </Routes>
      </main>
      <footer>
        <TheFooter />
      </footer>
    </div>
  );
}

export default App;
