//bootstrap
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//components
import ServicesSpalinoveCesty from "../components/Services/ServicesSpalinoveCesty";
import ServicesVlozkovaniKominu from "../components/Services/ServicesVlozkovaniKominu";
import ServicesProhlidky from "../components/Services/ServicseProhlidky";
import ServicesOprava from "../components/Services/ServicesOprava";
import ServicesMontaze from "../components/Services/ServicesMontaze";
import ServicesFrezovani from "../components/Services/ServicesFrezovani";

//styles
import "./Services.scss";

function Services() {
  return (
    <section className="mt-5 services wow zoomIn">
      <Container>
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
          <Row>
            <Col md={3} className="mb-5">
              <ListGroup>
                <ListGroup.Item action href="#link1">
                  Kontrola spalinové cesty
                </ListGroup.Item>
                <ListGroup.Item action href="#link2">
                  Vložkování komínu
                </ListGroup.Item>
                <ListGroup.Item action href="#link3">
                  Prohlídky komínu inspekční kamerou
                </ListGroup.Item>
                <ListGroup.Item action href="#link4">
                  Oprava kouřovodů
                </ListGroup.Item>
                <ListGroup.Item action href="#link5">
                  Montáže vícevrstvých komínů
                </ListGroup.Item>
                <ListGroup.Item action href="#link6">
                  Frézování komínů
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <Tab.Content>
                <Tab.Pane eventKey="#link1">
                  <ServicesSpalinoveCesty />
                </Tab.Pane>
                <Tab.Pane eventKey="#link2">
                  <ServicesVlozkovaniKominu />
                </Tab.Pane>
                <Tab.Pane eventKey="#link3">
                  <ServicesProhlidky />
                </Tab.Pane>
                <Tab.Pane eventKey="#link4">
                  <ServicesOprava />
                </Tab.Pane>
                <Tab.Pane eventKey="#link5">
                  <ServicesMontaze />
                </Tab.Pane>
                <Tab.Pane eventKey="#link6">
                  <ServicesFrezovani />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
}
export default Services;
