//bootstrap
import Card from "react-bootstrap/Card";

function ServicesOprava(params) {
  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center">
        Oprava kouřovodů
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Dle potřeby provádíme opravy nebo výměny kouřovodů a vybíracích nebo
          kontrolních otvorů.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ServicesOprava;
