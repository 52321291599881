//bootsrap
import Card from "react-bootstrap/Card";

function ServicesFrezovani(params) {
  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center">
        Frézování komínů
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Problém s malým průměrem průduchu řeší komínová fréza, která pracuje
          na principu odstředivé síly, za pomoci vzduchové turbíny. Komínovou
          frézou lze zvětšit průměr rovného i uhýbaného komínového průduchu od ø
          100 do ø 350 mm bez sekání nebo zasahování do vnějšího zdiva komína.
        </Card.Text>
        <Card.Text>
          Frézování komínů se provádí zpravidla ústím komína ze střechy, popř.
          kontrolním nebo čistícím otvorem z půdního prostoru. Frézovat lze
          komínové zdivo z plných pálených cihel, komíny vytažené maltou i
          případné betonové věnce v místech průchodu komína stropy. Komíny
          osazené šamotovým, kameninovým, nebo azbestocementovým ochranným
          pouzdrem lze pouze vyčistit od dehtu, sazí a přetoků malty ve spojích.
          Při frézování je možné odebrat až 1/3 komínového zdiva. Po vlastním
          vyfrézování se musí komín vyvložkovat, aby nedocházelo k pronikání
          spalin stěnou zděného komína.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ServicesFrezovani;
