//styles
import "./PriceList.scss";

//bootstrap
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

function PriceList() {
  return (
    <section className="price-list mt-5 wow zoomIn">
      <Container>
        <h2 className="display-3 text-center mb-5">Ceník</h2>
        <Card id="price-list-card">
          <Card.Body className="d-flex justify-content-between col-12">
            <div>Samostatné čištění</div>
            <div>200 kč</div>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between col-12 border-top">
            <div>Samostatné čištění + výběr sazí </div>
            <div>300 kč</div>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between col-12 border-top">
            <div>Čištění + každoroční kontrola </div>
            <div>600 kč</div>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between col-12 border-top">
            <div>Samostatná každoroční kontrola </div>
            <div>500 kč</div>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between col-12 border-top">
            <div>Frézování </div>
            <div>900 kč / bm</div>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between col-12 border-top">
            <div>Vložkování komínů</div>
            <div>od 2000kč / bm</div>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
}
export default PriceList;
