//bootstrap
import Card from "react-bootstrap/Card";

function SpalinoveCesty() {
  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center">
        Kontrola spalinové cesty
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Kontrola spalinové cesty zahrnuje kompletní kontrolu po celé její
          délce od spalinového hrdla spotřebiče po sopouch a kontrolu komína od
          půdice komínového průduchu po jeho ústí. Kontrola může být provedena
          pouze za předpokladu, že spalinová cesta je v celé své délce
          vyčištěna.
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>
          Způsob kontroly spalinové cesty se provádí posouzením
        </Card.Title>
        <Card.Text>
          <ul className="services-ul">
            <li>
              toho, zda stav a provedení spalinové cesty v době kontroly
              odpovídá technickým požadavkům, podle kterých byla spalinová cesta
              navržena, provedena a bylo zahájeno její užívání
            </li>
            <li>
              toho, zda stav a provedení spalinové cesty zajistí, aby za všech
              provozních podmínek připojených spotřebičů paliv byly spaliny
              bezpečně odvedeny a rozptýleny do volného ovzduší
            </li>
            <li>
              zajištění volného a bezpečného přístupu ke spalinové cestě a k
              jejím vybíracím, vymetacím, kontrolním, měřicím a čisticím otvorům
            </li>
            <li>
              spalinové cesty z hlediska dodržení bezpečných vzdáleností od
              hořlavých předmětů a stavebních hmot třídy reakce na oheň B až F
            </li>
            <li>
              zajištění požární bezpečnosti viditelných a přístupných míst
              spalinové cesty, zvláště při prostupu spalinové cesty stavebními
              konstrukcemi, půdním prostorem nebo střechou a vývodů spalin
              obvodovou stěnou stavby
            </li>
            <li>
              jejího stavebně technického stavu a toho, zda nedošlo k zásadním
              změnám oproti stavu zjištěnému při minulé kontrole nebo revizi
            </li>
          </ul>
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Čištění spalinových cest</Card.Title>
        <Card.Text>
          je technologický postup, při kterém dochází k mechanickému odstranění
          všech nežádoucích nečistot, které vznikají při spalování. K čištění
          komínu patří i vybírání pevných znečišťujících části nahromaděných v
          neúčinné výšce komínového průduchu a odstraňování kondenzátů ze
          spalinové cesty. Při čištění se čistí i zařízení instalována na
          spalinové cestě jako jsou lapače jisker, komínové hlavice, regulátory
          tahu apod. Čištěním dosáhneme původní světlosti průduchu, který
          zajišťuje bezpečný odvod spalin do volného ovzduší.
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Zanášení spalinové cesty výrazně ovlivňuje</Card.Title>
        <Card.Text>
          <ul className="services-ul">
            <li>vhodnost a kvalita použitého paliva</li>
            <li>seřízení spotřebiče paliv</li>
            <li>kvalita a vhodnost komína</li>
            <li>obsluha spotřebiče paliv</li>
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default SpalinoveCesty;
