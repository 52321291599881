//assets & styles
import "./TheNavigation.scss";
import logo from "../assets/logo.svg";

//bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

function TheNavigation() {
  return (
    <Navbar bg="light" expand="md" className="shadow">
      <Container>
        <NavLink to="/" id="logo">
          <img src={logo} alt="logo" className="logo-header" />
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 ps-2">
            <NavLink to="/">Úvod</NavLink>
            <NavLink to="/sluzby">Služby</NavLink>
            <NavLink to="/cenik">Ceník</NavLink>
            <NavLink to="/kontakt">Kontakt</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default TheNavigation;
