//bootstrap
import Card from "react-bootstrap/Card";

function ServicesMontaze() {
  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center">
        Montáže vícevrstvých komínů
      </Card.Header>
      <Card.Body>
        <Card.Text>
          se používá pro vytvoření nové spalinové cesty, jsou vhodné pro paliva
          plynná i pevná. Vícevrstvý systém se skládá z vložky, izolace a
          pláště. Velmi často se používají v novostavbách, bytových domech, ale
          i v průmyslu.
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Přednosti nerezových vícevrstvých komínů</Card.Title>
        <Card.Text>
          <ul className="services-ul">
            <li>široké možnosti použití</li>
            <li>nízká hmotnost</li>
            <li>rychlá montáž</li>
            <li>možnost dodatečné přestavby</li>
            <li>okamžitá připravenost k provozu</li>
            <li>optimální proudění spalin</li>
            <li>nepotřebuje základ</li>
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ServicesMontaze;
