//bootstrap
import Card from "react-bootstrap/Card";

function ServicesProhlidky(params) {
  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center">
        Prohlídky komínu inspekční kamerou
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Slouží k zajišťování stavu spalinové cesty bez nutnosti vybourání
          otvorů. Kameru je možno spouštět ústím komínu. Celou prohlídku je
          možné přímo sledovat na obrazovce s možností pořízení videozáznamu.
          Inspekční kamera je určena především vyhledávání poškozených míst ve
          spalinové cestě, které nelze zjistit běžnou prohlídkou.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ServicesProhlidky;
