//styles
import "./Home.scss";

//bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Home() {
  return (
    <div>
      <section className="image pt-5 mb-5" id="jumbotron">
        <div className="jumbotron jumbotron-fluid wow slideInLeft">
          <Container>
            <h1
              className="display-1 wow bounceInDown"
              data-wow-duration="1s"
              data-wow-delay=".6s"
            >
              Kominictví Martin Juroš
            </h1>
            <hr className="my-3" />
            <p className="lead">
              Objednejte si kominíka včas a nečekejte, až nastane nějaký
              problém. Provádím pravidelnou kontrolu a čištění spalinových cest
              dle § 44 zákona č. 320/2015 sb. a vyhlášky č. 34/2016 sb.
            </p>
          </Container>
        </div>
      </section>

      <section>
        <Container>
          <h2 className="display-5 text-center mb-5">
            Základy správného užívání paliv
          </h2>
          <Row className="wow zoomIn" data-wow-offset="200">
            <Col>
              <Card md={6} className="mb-4 shadow-sm">
                <Card.Header className="text-center">Umístění</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Podle druhu prostoru je nutné správně zvolit i druh
                    spotřebiče. Ne každý spotřebič může být umístěn v jakémkoliv
                    prostoru, např. v garážích nebo autodílnách je možné umístit
                    pouze spotřebiče pro tyto prostory vhodná.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">Připojení</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Pro správnou funkci spotřebičů je potřebné i jejich odborné
                    připojení k příslušnému topnému médiu (plyn, elektřina, LTO
                    apod.). Správné připojení bývá v převážné většině i
                    předmětem výchozích zkoušek nebo revizí.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Dostatečný přívod vzduchu
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Většina spotřebičů potřebuje ke své správné funkci i
                    dostatek spalovacího vzduchu. Posouzení tohoto požadavku,
                    popř. stanovení dalších případných opatření, bývá součástí
                    příslušné technické, či projektové dokumentace.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">Odvod spalin</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Spaliny je třeba bezpečně odvést od spotřebiče do volného
                    prostoru – většinou do komína. Správné připojení kouřovodu
                    či odtahu spalin od spotřebičů podléhá revizi a pravidelné
                    kontrole.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Dodržování bezpečných vzdáleností
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Každý spotřebič by měl mít výrobcem stanoveny bezpečné
                    vzdálenosti od hořlavých předmětů. Jejich dodržování je
                    důležité pro zabránění vzniku požáru vlivem sálavého tepla.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Dodržování návodů k obsluze
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Ke každému spotřebiči jeho výrobce přikládá i návod k
                    obsluze. S tímto návodem je třeba se seznámit a při
                    používání spotřebiče tyto zásady správného používání a
                    manipulace i respektovat.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Používání předepsaného paliva
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Každý spotřebič je konstruován na určitý druh paliva, jehož
                    používání je nutné dodržovat. Zejména u spotřebičů na pevná
                    paliva bývá tato zásada často porušována.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Nehořlavé a izolační podložky, nehořlavé podlahy
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Uplatňují se zejména u spotřebičů na pevná paliva a brání
                    vzniku požáru od vypadlých hořících částí paliva.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Používat schválená topidla
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Výrobce je povinen garantovat správnou a bezpečnou funkci
                    spotřebiče. Proto nejsou povoleny žádné „domácí“ úpravy a
                    vylepšení spotřebičů. Mohly by být opět příčinou vzniku
                    požáru, výbuchu nebo jiné havárie.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  Pravidelné čištění, kontroly a revize komínů, kouřovodů a
                  spotřebičů paliv
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Četnost těchto úkonů stanoví zákon o Hasičském záchranném
                    sboru České republiky č. 320/2015 Sb. Dodržovat tyto termíny
                    je i v současné době povinností každého uživatele v rámci
                    obecné prevenční povinnosti zakotvené občanským zákoníkem a
                    zákonem o požární ochraně. Komíny, popř. i jiná vyústění
                    spalin, které čištění nebo jinou údržbu nevyžadují,
                    doporučujeme rovněž občas prohlédnout a překontrolovat.
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  Kontrolu komína by měla dělat odborná kominická firma
                  (kominík), která o výsledku kontroly a případném vyčistění
                  komína vystaví řádný doklad s razítkem a podpisem. Doklad musí
                  mimo jiné obsahovat datum kontroly a vyjádření, zda je komín
                  schopen bezpečného provozu. O přezkoušení komína je také třeba
                  požádat při změně topného zařízení či paliva.
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default Home;
